












import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import RequisitionForm from '@/components/RequisitionForm.vue'
import { Project } from '@/interfaces'

export default Vue.extend({
  components: {
    Nav,
    RequisitionForm,
  },
  methods: {
    requisitionCreated(project: Project) {
      if (project.id) {
        this.$router.push({
          name: 'requisition_edit',
          params: {
            projectId: project.id.toString(),
            requisitionId: project.requisition?.id ? project.requisition.id.toString() : '',
          },
        })
      }
    },
  },
})
